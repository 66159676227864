import React from 'react';
import { Text } from '@audi/audi-ui-react';
import { ElementProps } from '../reconstructWithDesignComponents/findComponentTypeUtil';

export const BlockQuoteComponent = ({ item, key }: ElementProps) => {
  const { properties } = item;

  return (
    <blockquote cite={properties?.cite} key={key}>
      <Text as="p" variant="copy1">
        {item?.children[0]?.value}
      </Text>
    </blockquote>
  );
};
