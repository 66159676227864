import { Text } from '@audi/audi-ui-react';
import React from 'react';
import parse from 'html-react-parser';
import { ElementProps } from '../reconstructWithDesignComponents/findComponentTypeUtil';

export const PreformattedTextComponent = ({ item, key }: ElementProps) => {
  const { children } = item;
  return (
    <Text as="span" spaceStackEnd="m" key={key}>
      {parse(`<pre>${children[0]?.value}</pre>`)}
    </Text>
  );
};
