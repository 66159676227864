import { ContentFragment } from '@oneaudi/falcon-tools';

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    theme: string;
    bodyText: string;
    justification: 'center' | 'auto' | 'baseline' | 'end' | 'start' | 'stretch';
    ctaButtonLabel: string;
    ctaButtonUrl: string;
    ctaButtonOpenInNewTab: boolean;
  };
}

export interface AppContent {
  __type?: 'default';
  theme: string;
  bodyText: string;
  justification: 'center' | 'auto' | 'baseline' | 'end' | 'start' | 'stretch';
  ctaButtonLabel: string;
  ctaButtonUrl: string;
  ctaButtonOpenInNewTab: boolean;
}

function mapContent(content: AppContent | FalconContent): AppContent {
  if (content?.__type !== 'aem-headless') {
    return content;
  }

  return normalizedContent(content);
}

const normalizedContent = (content: FalconContent): AppContent => {
  const { fields } = content;
  const { theme, bodyText, justification, ctaButtonLabel, ctaButtonUrl, ctaButtonOpenInNewTab } =
    fields;

  const contentForCMS: AppContent = {
    theme,
    bodyText,
    justification,
    ctaButtonLabel,
    ctaButtonUrl,
    ctaButtonOpenInNewTab,
  };

  const formattedContent: AppContent = {
    ...contentForCMS,
  };

  return formattedContent;
};

export default mapContent;
