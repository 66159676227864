import {
  AudiPlatformProvider,
  audiDarkTheme,
  audiLightTheme,
  Layout,
  LayoutItem,
  Button,
} from '@audi/audi-ui-react';
import React from 'react';
import { Container, ButtonContainer, TextWrapper } from './style';
import { convertToSanitzedString } from './editorialTextUtils';
import { reconstructWithDesignComponents } from '../reconstructWithDesignComponents/reconstructWithDesignComponents';

export interface EditorialTextContent {
  bodyText: string;
  theme: string;
  justification: 'center' | 'auto' | 'baseline' | 'end' | 'start' | 'stretch';
  ctaButtonLabel: string;
  ctaButtonUrl: string;
  ctaButtonOpenInNewTab: boolean;
  textForRehype?: string;
}

type Props = {
  content: EditorialTextContent;
};

const EditorialText = ({ content }: Props) => {
  const { bodyText, theme, justification, ctaButtonLabel, ctaButtonUrl, ctaButtonOpenInNewTab } =
    content;

  const sanitzizedString = convertToSanitzedString(bodyText);
  const designComponentsArray = reconstructWithDesignComponents(sanitzizedString);

  return (
    <AudiPlatformProvider theme={theme === 'dark' ? audiDarkTheme : audiLightTheme}>
      {bodyText && (
        <Container theme={theme}>
          <Layout data-test-id="title" direction="column">
            <LayoutItem align="auto" basis="100%">
              <TextWrapper align={justification} theme={theme}>
                {designComponentsArray?.length > 0 &&
                  designComponentsArray.map((designComponent: JSX.Element) => {
                    return designComponent;
                  })}
              </TextWrapper>
            </LayoutItem>

            {ctaButtonUrl?.length > 0 && ctaButtonLabel?.length > 0 && (
              <LayoutItem align={justification} basis="auto">
                <ButtonContainer>
                  <Button
                    variant="text"
                    aria-label={ctaButtonLabel}
                    newWindow={ctaButtonOpenInNewTab}
                    href={ctaButtonUrl}
                    onClick={() => {}}
                  >
                    {ctaButtonLabel}
                  </Button>
                </ButtonContainer>
              </LayoutItem>
            )}
          </Layout>
        </Container>
      )}
    </AudiPlatformProvider>
  );
};

export default EditorialText;
