import { unified } from 'unified';
import type { Node } from 'unist';
import rehypeParse from 'rehype-parse';
import rehypeSanitize from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';
import { visit } from 'unist-util-visit';
import { NodeForProcess, findComponentTypeUtil } from './findComponentTypeUtil';

export const reconstructWithDesignComponents = (contentString: string) => {
  const components: JSX.Element[] = [];

  unified()
    .use(rehypeParse, {
      fragment: true,
    })
    .use(() => {
      return (tree: Node) => {
        visit(tree, 'element', (node: NodeForProcess, index, parent: NodeForProcess) => {
          const { line, column, offset } = node.position.start;
          const uniqueKey = `${index}_${line}-${column}-${offset}`;

          const elementObject = {
            item: node,
            key: uniqueKey,
            parent,
          };
          const componentType = findComponentTypeUtil(elementObject);

          if (componentType) {
            components.push(componentType);
          }
        });
      };
    })
    .use(rehypeSanitize)
    .use(rehypeStringify)
    .processSync(contentString);

  return components;
};
