import React, { useEffect, useState } from 'react';
import { App, ProviderProps as AppProps } from '@oneaudi/oneaudi-os-react';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import {
  ContentServiceV1 as ContentService,
  ContentChangeListener,
} from '@volkswagen-onehub/audi-content-service';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { createTrackingService } from '@volkswagen-onehub/oneaudi-os-adapter-tracking';
import type { AppContent } from './components/contentMapping/contentMapping';
import EditorialText, { EditorialTextContent } from './components/EditorialText/EditorialText';
import mapContent from './components/contentMapping/contentMapping';

import mockContent from './content.json';

interface FeatureAppProps extends AppProps {
  configProvider?: GfaServiceConfigProviderV1;
  contentService?: ContentService;
  trackingService?: TrackingServiceV2;
  content?: EditorialTextContent;
}

const FeatureApp: React.FC<FeatureAppProps> = ({ ...inherited }: FeatureAppProps) => {
  let trackingService = {} as TrackingServiceV2;

  if (typeof window !== 'undefined') {
    trackingService = inherited.trackingService || createTrackingService(__FEATURE_APP_NAME__);
  }

  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };
  const { contentService } = inherited;

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };
  const [rawContent, setRawContent] = useState(contentService?.getContent() as AppContent);

  useEffect(() => {
    trackingService.featureAppName = __FEATURE_APP_NAME__;
    const readyEvent = {
      event: {
        attributes: {
          componentName: __FEATURE_APP_NAME__,
          elementName: '',
          label: '',
          clickID: '',
          pos: '',
          targetURL: '',
          value: '',
        },
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: `${__FEATURE_APP_NAME__} - Feature App ready`,
        },
      },
    };
    trackingService.track(readyEvent);
  }, []);

  const content = mapContent(rawContent);
  contentService?.onContentChange(setRawContent as ContentChangeListener);

  const isSSR = typeof window === 'undefined';
  const [onFirstRender, setOnFirstRender] = useState(true);
  useEffect(() => {
    setOnFirstRender(false);
  }, []);

  return (
    <App config={appConfig} app={app} {...inherited}>
      {isSSR || onFirstRender ? null : <EditorialText content={content ?? mockContent} />}
    </App>
  );
};

export default FeatureApp;
