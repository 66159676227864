import sanitizeHtml from 'sanitize-html';

export const allowedTagsAndAttributes = {
  allowedTags: [
    'b',
    'u',
    'strong',
    'a',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ul',
    'ol',
    'li',
    'pre',
    'blockquote',
    'hr',
    'span',
    'div',
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target', 'rel'],
    h3: ['data-test-id'],
    span: ['style'],
    blockquote: ['cite'],
  },
  allowedStyles: {
    span: {
      'text-decoration': [/^underline$/],
    },
  },
};

/* Replace shortcode - [ADD_DIVIDER] with <hr> */
export const checkedForDivider = (copy: string) => {
  const divider = /<p>.?(.?&nbsp;)*?\[ADD_DIVIDER\](.?&nbsp;)*?.?<\/p>/gi;
  const dividerComponent = copy ? copy.replace(divider, '<hr>') : '';

  return dividerComponent;
};

/* Replace shortcode - [HYPHEN] with &shy; */
export const checkedForHyphen = (copy: string) => {
  const hyphen = /\[HYPHEN\]/gi;
  const hyphenComponent = copy ? copy.replace(hyphen, '&shy;') : '';

  return hyphenComponent;
};
// eslint-disable-next-line
const shortcodeUrlPattern =
  /<p>.?(.?&nbsp;)*?\[ADD_BQ::[\w '"_.-]*::CITE::([https?://(?:www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b)?(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)?]*::\](.?&nbsp;)*?.?<\/p>/gi;

/* Replace shortcode - [ADD_BQ::<text>::] with <blockquote> */
export const checkedForBlockQuote = (copy: string) => {
  let finalCopy: string = copy;

  const regex = new RegExp(shortcodeUrlPattern);
  const quotes = copy?.match(regex);

  // pull content an wrap with <blockquote>
  // will always be in index position 1
  quotes?.forEach((quote) => {
    const splitQuote = quote?.split('::');
    finalCopy = finalCopy?.replace(
      quote,
      `<blockquote cite="${splitQuote?.[3]}">${splitQuote?.[1]}</blockquote>`,
    );
  });

  return `${finalCopy}`;
};

export const convertToSanitzedString = (text: string) => {
  const textWithHyphens = checkedForHyphen(text);
  const replacementDivider = checkedForDivider(textWithHyphens);
  const finalCopy = checkedForBlockQuote(replacementDivider);
  const sanitized = sanitizeHtml(finalCopy, allowedTagsAndAttributes);

  return sanitized;
};
