import { BlockQuoteComponent as BlockQuote } from '../BlockQuote/BlockQuoteComponent';
import { DividerComponent as Divider } from '../Divider/DividerComponent';
import { ListComponent as List } from '../List/ListComponent';
import { PreformattedTextComponent as PreformattedText } from '../PreFormattedText/PreFormattedText';
import { TextComponent as Text } from '../Text/TextComponent';

export interface SubChild {
  type: string;
  value: string;
}
export interface NodeChildContent {
  children?: SubChild[];
  properties?: { cite?: string; href?: string; style?: string; target?: string };
  type: string;
  value: string;
  // eslint-disable-next-line
  tagName?: any;
}
export interface NodeForProcess extends NodeChildContent {
  children: NodeChildContent[] | SubChild[];
  position: { start: { line: number; column: number; offset: number } };
  type: string;
}

export interface ElementObject {
  item: NodeForProcess;
  key: string;
  parent: NodeForProcess;
}

export interface ElementProps {
  item: NodeForProcess;
  // eslint-disable-next-line
  variant?: any;
  parent?: NodeForProcess;
  key?: string;
}
export const findComponentTypeUtil = ({ item, key, parent }: ElementObject) => {
  switch (item.tagName) {
    case 'h1':
      return Text({ item, variant: 'order1', parent, key });

    case 'h2':
      return Text({ item, variant: 'order2', parent, key });

    case 'h3':
      return Text({ item, variant: 'order3', parent, key });

    case 'h4':
      return Text({ item, variant: 'order4', parent, key });

    case 'h5':
      return Text({ item, variant: 'order4', parent, key });

    case 'h6':
      return Text({ item, variant: 'order4', parent, key });

    case 'p':
      return Text({ item, variant: 'copy1', key });

    case 'div':
      return Text({ item, variant: 'copy1', key });

    case 'ul':
      return List({ item, variant: 'bullet', key });

    case 'ol':
      return List({ item, variant: 'decimal', key });

    case 'hr':
      return Divider({ item, key });

    case 'blockquote':
      return BlockQuote({ item, key });

    case 'pre':
      return PreformattedText({ item, key });

    default:
      return null;
  }
};
