import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { ElementProps } from '../reconstructWithDesignComponents/findComponentTypeUtil';
import { produceElementForTextComponent } from './textUtils';

const headingTagNames = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export const TextComponent = ({ item, variant, parent, key }: ElementProps) => {
  // eslint-disable-next-line
  const { tagName } = item;
  const hasListItemParent = parent?.tagName === 'li';
  // eslint-disable-next-line
  const isHeadingTag = headingTagNames.includes(tagName);

  return (isHeadingTag && !hasListItemParent) || tagName === 'p' || tagName === 'div' ? (
    // eslint-disable-next-line
    <Text as={tagName} variant={variant} key={key} spaceStackEnd="m">
      {produceElementForTextComponent(item)}
    </Text>
  ) : null;
};
