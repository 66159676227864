import { List, ListItem } from '@audi/audi-ui-react';
import React from 'react';
import parse from 'html-react-parser';
import {
  ElementProps,
  NodeChildContent,
  SubChild,
} from '../reconstructWithDesignComponents/findComponentTypeUtil';

// eslint-disable-next-line
export const ListComponent = ({ item, variant, key }: ElementProps) => {
  const { children } = item;

  // filter only element type children, text type should not be used at this level
  const childrenElements = children?.filter((child: NodeChildContent) => child.type === 'element');
  const listItems = childrenElements.map((listItem: NodeChildContent, index: number) => {
    const keyValue = index.toString();
    let listItemValue: string = '';

    if (listItem?.children) {
      const subChildrenElements: NodeChildContent[] = listItem.children?.filter(
        (child: SubChild) => child.type === 'element',
      );

      let listItemContent: string = '';
      // when no element types present, means contents do not contain inline tags
      // use the values to populate list item
      if (subChildrenElements.length === 0) {
        listItemContent = listItem?.children[0]?.value;
      }
      // should only ever have one position when populated
      const [singleSubElement] = subChildrenElements;

      if (singleSubElement) {
        const { children: singleSubChild } = singleSubElement;

        if (singleSubChild) {
          // should only ever have one position when populated
          const [childText] = singleSubChild;

          listItemContent = parse(
            `<${singleSubElement.tagName} key=${key}>${childText.value}</${singleSubElement.tagName}>`,
          ) as string;
        }
      }

      listItemValue = listItemContent;
    }

    return <ListItem key={`${keyValue}_${listItemValue}`}>{listItemValue}</ListItem>;
  });

  return (
    // eslint-disable-next-line
    <List variant={variant} spaceStackEnd="m" key={key}>
      {listItems.map((listItem: React.JSX.Element) => {
        return listItem;
      })}
    </List>
  );
};
