import parse from 'html-react-parser';
import {
  NodeForProcess,
  NodeChildContent,
} from '../reconstructWithDesignComponents/findComponentTypeUtil';

// converts item values into string and inlcudes any nested inline elements eg. <strong> or <a>
export const produceElementForTextComponent = (item: NodeForProcess) => {
  const content = parse(checkForInlineElements(item).join('')) as string;

  return content;
};

export const checkForInlineElements = (parentItem: NodeForProcess) => {
  const textStyles = parentItem.children.map((item: NodeForProcess | NodeChildContent) => {
    const singleTextElement: string | undefined =
      parentItem.children.length < 2 || !item.tagName ? item.value : undefined;

    let elementValue = singleTextElement;

    if (!singleTextElement && item.children) {
      // eslint-disable-next-line
      const { children, tagName: currentTagName, properties } = item;
      const [innerElement] = children;

      const { value: innerValue } = innerElement;

      const linkHref = properties?.href;
      const linkTarget = properties?.target || '_self';
      const spanStyle = properties?.style;

      // TODO: build out attributes dynamically so we can support anything that is passed in
      // TODO: for example, in the current state we don't return `id` or `data-*` attributes
      elementValue =
        currentTagName === 'a'
          ? `<${currentTagName} href="${linkHref}" target="${linkTarget}">${innerValue}</${currentTagName}>`
          : `<${currentTagName} style="${spanStyle}">${innerValue}</${currentTagName}>`;
    }

    return elementValue;
  });

  return textStyles;
};
